export const InitiativesByOrigin = {
  "Organizational Unit": [
    "Internal Awareness Activities",
    "Network & Structure",
    "Company Strategy / Management Push",
    "External Event",
  ],
  "Venture Client Unit": [
    "Company Strategy / Management Push",
    "External Event",
  ],
  Startup: [
    "External Awareness Activities",
    "Online Presence & Channels",
    "External Event",
  ],
  "Third Party": [],
};
