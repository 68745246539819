import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Chip,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Project } from "../../../../../Types/Project";
import OverviewSection from "./OverviewSection/OverviewSection";
import SolutionFitMatrixTable from "../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/SolutionFitMatrixTable";
import SolutionFitMatrixHeader from "../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixHeader/SolutionFitMatrixHeader";
import ProjectDetailsAccordionSummary from "../../../SharedComponents/ProjectDetailsAccordionSummary";
import { StartupHttpService } from "../../../../../Http/Startup/Startup.http.service";
import { Startup } from "../../../../../Types/Startup";
import { getErrorMessage } from "../../../../../utils";
import { enqueueSnackbar } from "notistack";
import SuggestedStartups from "./SuggestedStartups/SuggestedStartups";
import theme from "../../../../../theme";
import {
  CreateOpportunityDTO,
  Opportunity,
} from "../../../../../Types/Opportunity";
import OpportunityHttpService from "../../../../../Http/Opportunity/Opportunity.Http.service";
import usePitchbookSync from "../../../../../Hooks/usePitchbookSync";
import { GlobalLoaderContext } from "../../../../../Context/LoaderContext";
import { AxiosError } from "axios";

const SuggestedStartupsWrapper = styled(Box)(() => ({
  minWidth: "calc(100% - 378px)",
  borderTop: `1px solid ${theme.palette.surface.medium}`,
  borderLeft: `1px solid ${theme.palette.surface.medium}`,
  borderRadius: theme.shape.radius.cardMedium,
}));

const VerticalTitleSplitter = styled(Typography)(() => ({
  writingMode: "vertical-rl",
  transform: "rotate(180deg)",
  alignSelf: "center",
  width: "49px",
  display: "flex",
  padding: theme.spacing(2),
  color: theme.palette.text.disabled,
  ...theme.typography.body2,
}));

const StyledGrid = styled(Grid)(() => ({
  mt: 3,
  isolation: "isolate",
  "& .flipper": {
    width: "100%",
    overflowX: "auto",
    scrollBehavior: "smooth",
  },
}));

const SUGGESTIONS_LIMIT = 10;

interface CuratedListProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: () => void;
  showFullList: boolean;
  onFullWidthButtonClick: () => void;
}

export default function CuratedList(props: CuratedListProps): ReactElement {
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { syncStartup } = usePitchbookSync();
  const [selectedDetailsView, setSelectedDetailsView] = useState(true);
  const [isAddingStartup, setIsAddingStartup] = useState(false);
  const [prevOpportunities, setPrevOpportunities] = useState<Opportunity[]>([]);
  const [suggestedStartups, setSuggestedStartups] = useState<Startup[]>([]);
  const [suggestedStartupsView, setSuggestedStartupsView] = useState(
    props.project.funnelStage === "discover"
  );
  const startupIds: number[] = props.project.opportunities.map(
    (opp) => opp.startupId
  );

  useEffect(() => {
    // if an opportunity was created from curated list or removed
    const currentStartupsIds = props.project.opportunities.map(
      (opp) => opp.startupId
    );
    if (props.project.opportunities.length != prevOpportunities.length) {
      setPrevOpportunities(props.project.opportunities);
    }
    if (suggestedStartupsView) getSuggestedStartups(currentStartupsIds, true);
  }, [props.project.opportunities.length, suggestedStartupsView]);

  const getSuggestedStartups = async (
    startupIds: number[],
    shuffle?: boolean
  ) => {
    if (startupIds.length > 0) {
      setGlobalLoader(true);
      await StartupHttpService.getSimilarStartups(startupIds, SUGGESTIONS_LIMIT)
        .then((startups) => {
          if (shuffle) setSuggestedStartups(startups);
          else swapSuggestion(startups, startupIds[startupIds.length - 1]); // last added suggested startup id
        })
        .catch((error: any) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, { variant: "error" });
          setGlobalLoader(false);
        });
      setGlobalLoader(false);
    } else {
      setSuggestedStartups([]);
    }
  };

  const swapSuggestion = (incomingStartups: Startup[], startupId: number) => {
    setGlobalLoader(true);
    const missingStartups = incomingStartups.filter(
      (incomingStartup) =>
        !suggestedStartups.some(
          (suggestion) => suggestion.id === incomingStartup.id
        )
    );

    if (missingStartups.length) {
      let newSuggestedStartups = [];
      const highestRatedStartup = missingStartups[0];

      const pickedIndex = suggestedStartups.findIndex(
        (suggestion) => suggestion.id === startupId
      );

      if (pickedIndex !== -1) {
        newSuggestedStartups = suggestedStartups;
        newSuggestedStartups[pickedIndex] = highestRatedStartup;
      } else {
        newSuggestedStartups = [...suggestedStartups, highestRatedStartup];
      }

      if (suggestedStartups.length < SUGGESTIONS_LIMIT) {
        const difference = SUGGESTIONS_LIMIT - suggestedStartups.length;
        const startupsToAdd = missingStartups.slice(1, 1 + difference);

        newSuggestedStartups = [...suggestedStartups, ...startupsToAdd];
      }

      setSuggestedStartups(newSuggestedStartups);
    } else {
      setSuggestedStartups((prevSuggestions) => {
        const newSuggestions = prevSuggestions.filter(
          (suggestion) => !startupIds.includes(suggestion.id)
        );

        return newSuggestions;
      });
    }
    setGlobalLoader(false);
  };

  const handleCreateOpportunity = async (startup: Startup) => {
    const createdOpportunity = {
      projectId: props.project.id,
      startupId: startup.id,
      isQualified: false,
      isSelectedForPilot: false,
      rating: null,
    } as CreateOpportunityDTO;

    setGlobalLoader(true);

    try {
      await OpportunityHttpService.createOpportunity(createdOpportunity).then(
        async () => {
          startupIds.push(startup.id);
          setPrevOpportunities([
            ...prevOpportunities,
            createdOpportunity as Opportunity,
          ]);
          if (suggestedStartupsView) await getSuggestedStartups(startupIds);

          props.handleSave();
          await syncStartup(createdOpportunity.startupId);
        }
      );
    } catch (error) {
      const errorMessage = getErrorMessage(error as AxiosError);
      return enqueueSnackbar(
        `Something went wrong while creating opportunity: ${errorMessage}`,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <ProjectDetailsAccordionSummary
        $fullWidth
        $fullyExpanded={props.showFullList}
        $handleFullWidthButton={props.onFullWidthButtonClick}
      >
        <Box display="flex" gap={1} alignItems="center">
          Curated List
          {!props.expanded && (
            <Chip
              variant="counter"
              color="info"
              label={props.project.opportunities.length}
            />
          )}
        </Box>
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        <OverviewSection
          showFullList={props.showFullList}
          project={props.project}
          handleSave={props.handleSave}
        />
        {props.showFullList && (
          <StyledGrid container>
            <SolutionFitMatrixHeader
              setSelectedDetailsView={(view: boolean) =>
                setSelectedDetailsView(view)
              }
              setSuggestedStartupsView={setSuggestedStartupsView}
              suggestedStartupsView={suggestedStartupsView}
              selectedDetailsView={selectedDetailsView}
              projectFunnelStage="discover"
              opportunities={props.project.opportunities}
              project={props.project}
            />
            <Box width="100%">
              <SolutionFitMatrixTable
                projectId={props.project.id}
                clusters={props.project.solutionClusters}
                opportunities={props.project.opportunities}
                handleSave={props.handleSave}
                detailsView={selectedDetailsView}
                requirements={
                  props.project.requirements ? props.project.requirements : []
                }
                projectFunnelStage="discover"
                currentProjectStage={props.project.funnelStage}
                isAddingStartup={isAddingStartup}
                setIsAddingStartup={setIsAddingStartup}
                ventureClientId={props.project.businessUnit.ventureClientId}
              >
                {suggestedStartups &&
                suggestedStartups.length > 0 &&
                suggestedStartupsView ? (
                  <>
                    <VerticalTitleSplitter>
                      Suggested Startups
                    </VerticalTitleSplitter>
                    <SuggestedStartupsWrapper data-testid="suggestions-view">
                      <SuggestedStartups
                        startups={suggestedStartups}
                        detailsView={selectedDetailsView}
                        handleCreateOpportunity={handleCreateOpportunity}
                      />
                    </SuggestedStartupsWrapper>
                  </>
                ) : (
                  <></>
                )}
              </SolutionFitMatrixTable>
            </Box>
          </StyledGrid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
