import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  styled,
  Chip,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Container,
} from "@mui/material";
import React, { ReactElement, useRef, useState } from "react";
import theme from "../../../theme";
import { LeadProject } from "../../../Types/LeadProject";
import LeadProjectSummaryEditModal from "./LeadProjectSummaryEditModal";
import { EditOutlined } from "@mui/icons-material";
import ContactName from "../../Contacts/ContactCard/ContactName";
import {
  capitalizeFirstLetter,
  formatDate,
  getStatusColor,
} from "../../../utils";
import useClient from "../../../Hooks/useClient";

const StyledContactName = styled(ContactName)({
  gap: theme.spacing(1),
  flex: "initial",
  "& .contact-name": {
    ...theme.typography.body2,
  },
  "& .contact-title": {
    ...theme.typography.overline,
  },
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    placement="bottom-start"
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "256px",
    backgroundColor: theme.palette.surface.primary.main,
    boxShadow: theme.boxShadows[2],
  },
}));

const TruncatedText = styled(Typography)(
  ({ maxLines }: { maxLines: number }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: "vertical",
  })
);

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $statusColor?: string }>(({ $statusColor }) => ({
  color: $statusColor,
  borderColor: $statusColor,
  backgroundColor: theme.palette.icon.primaryInvert.disabled,
}));

interface ProjectDetailsHeaderProps {
  leadProject: LeadProject;
  projectNameRef: React.RefObject<HTMLElement>;
  bottomHeaderRef: React.RefObject<HTMLElement | undefined>;
  handleSave: (withScroll?: boolean) => void;
  handleClickLaunchProject: () => void;
}

const LeadProjectHeader = ({
  leadProject,
  projectNameRef,
  bottomHeaderRef,
  handleSave,
  handleClickLaunchProject,
}: ProjectDetailsHeaderProps): ReactElement => {
  const { background, hero } = useClient();
  const [modalOpen, setModalOpen] = useState(false);

  const lastModifiedDate = leadProject.lastModifiedDate
    ? formatDate(leadProject.lastModifiedDate)
    : null;

  const dateCreated = leadProject.dateCreated
    ? formatDate(leadProject.dateCreated)
    : null;

  const topHeader = useRef<HTMLElement>(null);

  const isDevelopment = process.env.NODE_ENV === "development";
  const baseS3Url = isDevelopment
    ? "https://vclos-frontend.s3.eu-central-1.amazonaws.com"
    : "https://vclos-frontend-prod.s3.eu-central-1.amazonaws.com";

  const backgroundUrl =
    hero || background || baseS3Url + "/project-details-header-bg.png";

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const spacingForBottomHeader = topHeader.current
    ? topHeader.current.getBoundingClientRect().height -
      parseInt(theme.spacing(3))
    : 0;

  const shouldLaunchProject = leadProject.leaders.some(
    (leader) =>
      leader.businessUnitId && leader.useCaseId && leader.projectLeaderId
  );

  return (
    <>
      <Box
        py={3}
        position="sticky"
        top={theme.spacing(-3)}
        zIndex={4}
        ref={topHeader}
        sx={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Box
            pt={10.5}
            display="flex"
            position="relative"
            justifyContent="space-between"
            alignItems="center"
            color="text.primaryInvert.main"
            gap={5}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              color="text.primaryInvert.main"
              maxWidth="60%"
              gap={2}
            >
              <TruncatedText
                ref={projectNameRef}
                variant="h3"
                maxLines={2}
                data-testid="project-name"
              >
                {leadProject.name}
              </TruncatedText>
              <Box>
                {leadProject.status == "archived" && (
                  <StatusChip
                    size="medium"
                    data-testid="project-status-chip"
                    label={capitalizeFirstLetter(leadProject.status)}
                    variant="outlined"
                    $statusColor={getStatusColor("archived white")}
                  />
                )}
              </Box>
            </Box>
            <StyledTooltip
              title={
                <Typography color="black" textAlign="center">
                  Please complete project leader details and link a use case to
                  continue.
                </Typography>
              }
              disableHoverListener={shouldLaunchProject}
            >
              <Box display="flex" gap={5} alignItems="center">
                <IconButton
                  data-testid="edit-lead-project-button"
                  color="secondary"
                  onClick={handleModalOpen}
                >
                  <EditOutlined />
                </IconButton>
                <Button
                  variant="contained"
                  onClick={handleClickLaunchProject}
                  data-testid="open-launch-project-modal-button"
                  disabled={
                    !shouldLaunchProject || leadProject.status == "archived"
                  }
                >
                  Launch Project
                </Button>
              </Box>
            </StyledTooltip>
          </Box>
        </Container>
      </Box>

      <Box
        pb={3}
        position="sticky"
        top={spacingForBottomHeader}
        sx={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
          backgroundSize: "cover",
        }}
        ref={bottomHeaderRef}
      >
        <Container>
          <Box
            display="flex"
            position="relative"
            justifyContent="space-between"
            color="text.primaryInvert.main"
          >
            <Stack gap={2} width={800}>
              <Box display="flex" gap={2} alignItems="center">
                <Box display="flex" gap={1} alignItems="center">
                  <TruncatedText
                    variant="subtitle2"
                    maxLines={2}
                    data-testid="project-venture-client"
                  >
                    {leadProject.ventureClient.name}
                  </TruncatedText>
                </Box>
              </Box>
              <TruncatedText
                maxLines={5}
                data-testid="project-short-description"
              >
                {leadProject.shortDescription}
              </TruncatedText>

              <Box display="flex" gap={4} sx={{ pointerEvents: "none" }}>
                {leadProject.projectOwner && (
                  <StyledContactName
                    name={leadProject.projectOwner.name}
                    title="Project Owner"
                    orientation="horizontal"
                    isVentureAssociate
                    invertColors
                  />
                )}

                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="body2"
                    color="text.primaryInvert.highEmphasis"
                  >
                    {dateCreated}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.primaryInvert.mediumEmphasis"
                  >
                    Created
                  </Typography>
                </Box>
                {lastModifiedDate && (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="body2"
                      color="text.primaryInvert.highEmphasis"
                    >
                      {lastModifiedDate}
                      {leadProject.lastModifiedBy
                        ? ` by ${leadProject.lastModifiedBy.name}`
                        : ""}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.primaryInvert.mediumEmphasis"
                    >
                      Last Edited
                    </Typography>
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>

      {modalOpen && (
        <LeadProjectSummaryEditModal
          handleModalClose={handleModalClose}
          modalOpen={modalOpen}
          leadProject={leadProject}
          onRefresh={handleSave}
        />
      )}
    </>
  );
};

export default LeadProjectHeader;
