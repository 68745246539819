import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  CreateLeadProjectDTO,
  LeadProject,
  LeadProjectOriginType,
} from "../../../Types/LeadProject";
import { ReactElement, Reducer, useReducer } from "react";
import theme from "../../../theme";
import CreateLeadOrigin from "../../UI/Modals/CreateLeadProjectModal/CreateLeadOrigin";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import CreateLeadInitiative from "../../UI/Modals/CreateLeadProjectModal/CreateLeadInitiative";
import { getErrorMessage } from "../../../utils";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

interface UpdatedLeadSourceRequiredFields {
  "Venture Client"?: number;
  originType?: LeadProjectOriginType;
  Contact?: number;
  Startup?: number;
  Category?: string;
  "Category Name"?: number;
  "Initiative Name"?: number;
}
interface Props {
  leadProject: LeadProject;
  modalOpen: boolean;
  refreshLead: () => void;
  setModalOpen: (state: boolean) => void;
}

const EditLeadProjectSource = ({
  leadProject,
  refreshLead,
  modalOpen,
  setModalOpen,
}: Props): ReactElement => {
  const [updatedLeadProject, setUpdatedLeadProject] = useReducer<
    Reducer<CreateLeadProjectDTO, Partial<CreateLeadProjectDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    (leadProject ?? {}) as CreateLeadProjectDTO
  );

  const updatedLeadSourceRequiredFields: UpdatedLeadSourceRequiredFields = {
    originType: updatedLeadProject.originType,
    ...(updatedLeadProject.originType === "Organizational Unit"
      ? {
          Contact: updatedLeadProject.originContactId || undefined,
        }
      : {}),
    ...(updatedLeadProject.originType === "Startup"
      ? {
          Startup: updatedLeadProject.originStartupId || undefined,
        }
      : {}),
    ...(updatedLeadProject.originType === "Third Party"
      ? {
          Category: updatedLeadProject.originThirdPartyCategory,
          "Category Name":
            updatedLeadProject.originThirdPartyCategoryNameId || undefined,
        }
      : {}),
    ...(updatedLeadProject.originType !== "Third Party" &&
    updatedLeadProject.initiativeType
      ? { "Initiative Name": updatedLeadProject?.initiativeNameId || undefined }
      : {}),
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(
      updatedLeadSourceRequiredFields
    )) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };
  const emptyRequiredFields = checkRequiredFields();

  const saveLeadProjectSource = async () => {
    try {
      await LeadProjectsHttpService.updateLeadProject(updatedLeadProject);
      setModalOpen(false);
      refreshLead();
    } catch (error) {
      const message = getErrorMessage(error as AxiosError);
      enqueueSnackbar(`Could not create lead project: ${message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      data-testid="edit-lead-project-source-modal"
    >
      <Stack gap={4}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...theme.typography.h3,
          }}
        >
          Edit Lead Source
        </DialogTitle>
        <Stack gap={1}>
          <Typography variant="subtitle1">Origin</Typography>
          <CreateLeadOrigin
            leadProject={updatedLeadProject}
            setLeadProject={setUpdatedLeadProject}
          />
        </Stack>

        {updatedLeadProject.originType !== "Third Party" && (
          <Stack gap={1}>
            <Typography variant="subtitle1">Initiative</Typography>
            <CreateLeadInitiative
              leadProject={updatedLeadProject}
              setLeadProject={setUpdatedLeadProject}
            />
          </Stack>
        )}

        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button
            onClick={saveLeadProjectSource}
            variant="contained"
            disabled={emptyRequiredFields.length > 0}
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default EditLeadProjectSource;
