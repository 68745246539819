import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Checkbox,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import theme from "../../../../../theme";
import { findLogo, normalizeUrl } from "../../../../../utils";
import { Launch, RocketLaunchOutlined } from "@mui/icons-material";
import { Opportunity } from "../../../../../Types/Opportunity";
import OpportunityHttpService from "../../../../../Http/Opportunity/Opportunity.Http.service";
import { useSnackbar } from "notistack";
import ProjectDetailsAccordionSummary from "../../../SharedComponents/ProjectDetailsAccordionSummary";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import useSaveProject from "../../../../../Hooks/useSaveProject";
import AssessmentReport from "./AssessmentReport";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";

const ASSESSMENT_DECISION_TEXT =
  "Please provide a brief explanation for selecting or not selecting this startup for pilot.";

const SectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  width: "100%",
}));

const SelectedStartupContainer = styled(Box)(() => ({
  display: "flex",
  alignSelf: "center",
  gap: "10px",
}));

const StyledTable = styled(Table, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isEmpty = false }: { $isEmpty?: boolean }) => ({
  display: "flex",
  width: $isEmpty ? "100%" : "fit-content",
}));

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isSelectedForPilot = false }: { $isSelectedForPilot?: boolean }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
  display: "block",
  borderRight: `1px solid ${theme.palette.borderOutline.soft}`,
  "&:first-of-type": {
    display: "flex",
    gap: theme.spacing(2),
    background: $isSelectedForPilot
      ? theme.palette.surface.success
      : theme.palette.surface.tertiary.dark,
    borderRight: `1px solid ${theme.palette.text.primaryInvert.main}`,
    color: theme.palette.text.primaryInvert.main,
    height: theme.spacing(7),
    ...theme.typography.subtitle2,
  },
}));

const EmptyTableCell = styled(StyledTableCell)(() => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.text.disabled,
  ...theme.typography.body1,
}));

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isHighlighted = false }: { $isHighlighted?: boolean }) => ({
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  float: "left",
  width: theme.spacing(36),
  boxShadow: $isHighlighted ? theme.boxShadows[6] : "none",
  "&:first-of-type": {
    width: theme.spacing(42),
    "& > td": {
      "&:first-of-type": {
        borderTopLeftRadius: theme.shape.radius.cardSmall,
      },
    },
  },
  "&:last-of-type": {
    "& > td": {
      borderRight: "none",
      "&:first-of-type": {
        borderTopRightRadius: theme.shape.radius.cardSmall,
      },
    },
  },
  "& > td": {
    "&:last-of-type": {
      backgroundColor: theme.palette.surface.secondary.light,
      borderBottom: 0,
    },
  },
}));

const SelectForPilotButton = styled(Checkbox)(() => ({
  color: theme.palette.text.primaryInvert.main,
  padding: 0,
  "& > svg": {
    color: theme.palette.text.primaryInvert.main,
  },
}));

const DecisionRationaleCell = styled(StyledTableCell)(() => ({
  flexGrow: 1,
  display: "flex",
  gap: theme.spacing(2),
  width: "100%",
  "&:hover .decision-rationale-edit": {
    opacity: 1,
  },
}));

const StyledDecisionRationaleInput = styled(CustomTextField)(() => ({
  width: "100%",
  height: "100%",
  "& .MuiInputBase-root": {
    border: 0,
  },
  "& .MuiInputBase-input": {
    background: "transparent",
  },
}));

interface AssessmentConclusionProps extends Omit<AccordionProps, "children"> {
  projectId: number;
  opportunities: Opportunity[];
  assessmentSummary: string | null;
  handleSave: () => void;
}

export default function AssessmentConclusion(
  props: AssessmentConclusionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const qualifiedOpportunities = props.opportunities.filter(
    (opp) => opp.isQualified
  );
  const opportunitiesSelectedForPilot = qualifiedOpportunities.filter(
    (opportunity) => opportunity.isSelectedForPilot
  );
  const selectedStartupLabel = (() => {
    if (opportunitiesSelectedForPilot.length > 1) {
      return opportunitiesSelectedForPilot.length;
    }
    const opportunitySelectedForPilot = opportunitiesSelectedForPilot[0];
    return opportunitySelectedForPilot?.startup.name || "--";
  })();

  const isTableEmpty = qualifiedOpportunities.length < 1;

  const handleOpportunityStatusChange = async (
    selectedOpportunity: Opportunity
  ) => {
    try {
      await OpportunityHttpService.updateOpportunity(
        {
          isSelectedForPilot: !selectedOpportunity.isSelectedForPilot,
        },
        selectedOpportunity.id
      );
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    } finally {
      props.handleSave();
    }
  };

  const handleUpdateDecisionRationale = async (
    opportunityId: number,
    assessmentDecision: string
  ) => {
    try {
      await OpportunityHttpService.updateOpportunity(
        {
          assessmentDecision,
        },
        opportunityId
      );
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    } finally {
      props.handleSave();
    }
  };

  const selectedStartupsText = `Startup${
    opportunitiesSelectedForPilot.length > 1 ? "s" : ""
  } Selected for Pilot`;

  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <ProjectDetailsAccordionSummary>
        Assessment Conclusion
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        <SectionContainer>
          <SelectedStartupContainer>
            <Typography>{selectedStartupsText}</Typography>
            <Chip
              variant={
                opportunitiesSelectedForPilot.length > 0 ? "choice" : "filled"
              }
              label={selectedStartupLabel}
              size="small"
              data-testid="selected-startup"
            />
          </SelectedStartupContainer>
          <TableContainer
            sx={{
              maxWidth: "calc(100vw - 544px)",
              overflowX: "scroll",
            }}
            data-testid="assessment-conclusion-table"
          >
            <StyledTable $isEmpty={isTableEmpty} aria-label="Product Demos">
              <TableBody
                sx={{ display: "flex", width: isTableEmpty ? "100%" : "auto" }}
              >
                <StyledTableRow
                  $isHighlighted={
                    isTableEmpty || opportunitiesSelectedForPilot.length === 0
                  }
                >
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell height={theme.spacing(8.5)}>
                    Startup
                  </StyledTableCell>
                  <StyledTableCell>Founding Year</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell sx={{ flexGrow: 1 }}>
                    Decision Rationale
                  </StyledTableCell>
                </StyledTableRow>
                {!isTableEmpty ? (
                  qualifiedOpportunities.map((opportunity) => (
                    <Row
                      key={opportunity.id}
                      handleOpportunityStatusChange={
                        handleOpportunityStatusChange
                      }
                      handleUpdateDecisionRationale={
                        handleUpdateDecisionRationale
                      }
                      opportunity={opportunity}
                    />
                  ))
                ) : (
                  <StyledTableRow style={{ width: "100%" }}>
                    <StyledTableCell></StyledTableCell>
                    <EmptyTableCell>There are no startups yet.</EmptyTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </SectionContainer>
        <AssessmentReport
          projectId={props.projectId}
          opportunities={props.opportunities}
          assessmentSummary={props.assessmentSummary}
          handleSave={props.handleSave}
        />
      </AccordionDetails>
    </Accordion>
  );
}

interface RowProps {
  opportunity: Opportunity;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  handleUpdateDecisionRationale: (
    opportunityId: number,
    assessmentDecision: string
  ) => Promise<void>;
}
function Row({
  opportunity,
  handleOpportunityStatusChange,
  handleUpdateDecisionRationale,
}: RowProps): ReactElement {
  const [editDecisionRationale, setEditDecisionRationale] = useState(false);
  const [currentValue, setCurrentValue] = useState(
    opportunity.assessmentDecision
  );

  const { setGlobalEditMode } = useContext(GlobalProjectEditContext);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const length = event.target.value.length;
    event.target.setSelectionRange(length, length);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await handleSave();
    } else if (event.key === "Escape") {
      setEditDecisionRationale(false);
      setGlobalEditMode(false);
    }
  };

  const handleSave = async () => {
    if (opportunity.assessmentDecision !== currentValue) {
      await handleUpdateDecisionRationale(opportunity.id, currentValue);
    }
    setEditDecisionRationale(false);
    setGlobalEditMode(false);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
  };

  useSaveProject(handleSave);

  const { startup, isSelectedForPilot } = opportunity;
  const startupLogo = findLogo(startup?.files);
  return (
    <StyledTableRow
      key={opportunity.id}
      $isHighlighted={isSelectedForPilot}
      data-testid={`assessment-conclusion-row-${opportunity.id}`}
    >
      <StyledTableCell $isSelectedForPilot={isSelectedForPilot}>
        <SelectForPilotButton
          size="small"
          checked={isSelectedForPilot}
          data-testid={`select-for-pilot-${opportunity.id}`}
          onClick={() => handleOpportunityStatusChange(opportunity)}
        />
        <Link target="_blank" href={`/startups/${startup.id}`} color="inherit">
          {startup.name}
        </Link>
      </StyledTableCell>
      <StyledTableCell
        height={theme.spacing(8.5)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link
          target="_blank"
          href={`/startups/${startup.id}`}
          height="100%"
          display="flex"
          alignItems="center"
        >
          {startupLogo ? (
            <Box
              component="img"
              sx={{
                width: theme.spacing(8),
                height: theme.spacing(6),
                objectFit: "contain",
              }}
              src={startupLogo}
            />
          ) : (
            <RocketLaunchOutlined color="disabled" />
          )}
        </Link>
        {startup.website && (
          <Link
            onClick={(event) => event.stopPropagation()}
            target="_blank"
            href={normalizeUrl(startup.website)}
            height="20px"
          >
            <Launch
              fontSize="small"
              htmlColor={theme.palette.icon.action.main}
              data-testid="startup-link-icon"
            />
          </Link>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {startup.dateFounded?.getFullYear() || "-"}
      </StyledTableCell>
      <StyledTableCell>{startup.billingCountry || "-"}</StyledTableCell>
      <DecisionRationaleCell
        data-testid={`decision-rationale-cell-${opportunity.id}`}
        onClick={() => {
          setEditDecisionRationale(true);
          setGlobalEditMode(true);
        }}
      >
        {editDecisionRationale ? (
          <StyledDecisionRationaleInput
            label="Decision Rationale"
            data-testid={`decision-rationale-${opportunity.id}`}
            editMode={true}
            autoFocus
            multiline
            value={currentValue}
            onBlur={() => {
              handleSave();
              setEditDecisionRationale(false);
            }}
            inputProps={{
              onFocus: handleFocus,
            }}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <>
            {!opportunity.assessmentDecision && (
              <ErrorOutlineIcon color="warning" />
            )}
            <Typography width="100%">
              {opportunity.assessmentDecision || ASSESSMENT_DECISION_TEXT}
            </Typography>
          </>
        )}
      </DecisionRationaleCell>
    </StyledTableRow>
  );
}
