import { FilterListOutlined, ViewColumnOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridPreferencePanelsValue } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { ReactElement } from "react";

interface ContactsDataGridToolbarProps {
  apiRef: React.MutableRefObject<GridApiPremium>;
}

export default function ContactsDataGridToolbar({
  apiRef,
}: ContactsDataGridToolbarProps): ReactElement {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      data-testid="dataGrid-tool-bar"
    >
      <Button
        startIcon={<ViewColumnOutlined />}
        onClick={() =>
          apiRef.current?.showPreferences(GridPreferencePanelsValue.columns)
        }
      >
        Columns
      </Button>
      <Button
        onClick={() => apiRef.current?.showFilterPanel("name")}
        startIcon={<FilterListOutlined />}
      >
        Filters
      </Button>
    </Box>
  );
}
