import { Box, IconButton, styled, Typography } from "@mui/material";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { ClientContactDTO } from "../../../Types/ClientContact";
import CreateClientContactModal from "./CreateClientContactModal";
import DetailsClientContactModal from "./DetailsClientcontactModal/DetailsClientContactModal";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CustomDataGrid from "../../ProjectList/CustomDataGrid";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { DataGridPremiumProps, GridColDef } from "@mui/x-data-grid-premium";
import theme from "../../../theme";
import { useSnackbar } from "notistack";
import useRoles from "../../../Hooks/useRoles";

interface ClientsDataGridProps extends Omit<DataGridPremiumProps, "columns"> {
  refreshClientContact: () => void;
  setCreateContactClientModalOpen: Dispatch<SetStateAction<boolean>>;
  createClientContactModalOpen: boolean;
  apiRef: React.MutableRefObject<GridApiPremium>;
}

type GridColumn = GridColDef & {
  hidden?: boolean;
};

const StyledButton = styled(IconButton)(() => ({
  display: "none",
  color: theme.palette.icon.action.main,
  padding: theme.spacing(0.5),
}));

const GridActionItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  width: "100%",
});

export default function ClientsDataGrid({
  apiRef,
  refreshClientContact,
  setCreateContactClientModalOpen,
  createClientContactModalOpen,
  ...defaultProps
}: ClientsDataGridProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const { isExternalUser } = useRoles();

  const [clientContactDetailsModalOpen, setClientContactDetailsModalOpen] =
    useState<boolean>(false);
  const [selectedClientContact, setSelectedClientContact] =
    useState<ClientContactDTO>();

  const handleClientDetailsModalOpen = (clientContact: ClientContactDTO) => {
    setClientContactDetailsModalOpen(true);
    setSelectedClientContact(clientContact);
  };

  const handleClientContactRefresh = () => {
    refreshClientContact();
  };

  const copyToClipboard = (
    e: React.MouseEvent<HTMLElement>,
    value: string,
    entity: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    enqueueSnackbar(entity + " successfully copied to clipboard", {
      variant: "success",
    });
  };

  const baseColumns: GridColumn[] = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 1,
      field: "departmentCode",
      headerName: "Department Code",
    },
    {
      flex: 1,
      field: "title",
      headerName: "Title",
    },
    {
      flex: 1,
      field: "businessUnit.name",
      headerName: "Organizational Unit",
      valueGetter: (_value, row) => {
        if (!row.businessUnit) {
          return "";
        }
        return `${row.businessUnit.name}`;
      },
    },
    {
      flex: 1,
      field: "ventureClientName",
      headerName: "Venture Client",
      hidden: isExternalUser,
      valueGetter: (_value, row) => {
        if (!row.businessUnit || !row.businessUnit.ventureClient) {
          return "";
        }
        return `${row.businessUnit.ventureClient.name}`;
      },
    },
    {
      flex: 1,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (
        <>
          {params.row.email && (
            <GridActionItem
              onClick={(e) => {
                copyToClipboard(e, params.row.email, "Email address");
              }}
            >
              <StyledButton className="copy-icon">
                <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
              </StyledButton>
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                color="text.action.main"
                noWrap
              >
                {params.row.email}
              </Typography>
            </GridActionItem>
          )}
        </>
      ),
    },
    {
      flex: 1,
      field: "phone",
      headerName: "Phone",
      renderCell: (params) => (
        <>
          {params.row.phone && (
            <GridActionItem
              onClick={(e) => {
                copyToClipboard(e, params.row.phone, "Phone");
              }}
            >
              <StyledButton className="copy-icon">
                <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
              </StyledButton>
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                color="text.action.main"
                noWrap
              >
                {params.row.phone}
              </Typography>
            </GridActionItem>
          )}
        </>
      ),
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <>
      <CustomDataGrid
        {...defaultProps}
        apiRef={apiRef}
        columns={columns}
        noRowsText="No Contact Found"
        onRowClick={(e) => {
          handleClientDetailsModalOpen(e.row);
        }}
      />
      {selectedClientContact && clientContactDetailsModalOpen && (
        <DetailsClientContactModal
          setModalOpen={setClientContactDetailsModalOpen}
          modalOpen={clientContactDetailsModalOpen}
          contact={selectedClientContact}
          handleSave={handleClientContactRefresh}
        />
      )}
      {createClientContactModalOpen && (
        <CreateClientContactModal
          setModalOpen={setCreateContactClientModalOpen}
          modalOpen={createClientContactModalOpen}
          handleSave={handleClientContactRefresh}
        />
      )}
    </>
  );
}
