import { Box, styled } from "@mui/material";
import { RocketLaunchOutlined } from "@mui/icons-material";
import theme from "../../../theme";
import { ReactElement } from "react";

const LogoPlaceholderBox = styled(Box)({
  display: "flex",
  width: "88px",
  height: "100%",
  padding: theme.spacing(0.75, 2),
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.surface.medium,
});

interface LogoPlaceholderProps {
  logoUrl?: string;
}

const LogoPlaceholder = ({ logoUrl }: LogoPlaceholderProps): ReactElement => {
  return (
    <Box display="flex" maxWidth={120} height={40} alignItems="center">
      {logoUrl ? (
        <Box
          component="img"
          width="100%"
          height="100%"
          sx={{
            objectFit: "contain",
            objectPosition: "left",
          }}
          src={logoUrl}
        />
      ) : (
        <LogoPlaceholderBox>
          <RocketLaunchOutlined fontSize="small" color="disabled" />
        </LogoPlaceholderBox>
      )}
    </Box>
  );
};

export default LogoPlaceholder;
