import { Box, TextField } from "@mui/material";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { SelectInput } from "../../InputFields/SelectInput";
import useRoles from "../../../../Hooks/useRoles";
import { VentureClient } from "../../../../Types/VentureClient";
import { CreateLeadProjectDTO } from "../../../../Types/LeadProject";
import { VentureClientHttpService } from "../../../../Http/VentureClient/VentureClient.http.service";
import { getErrorMessage } from "../../../../utils";
import { enqueueSnackbar } from "notistack";

interface CreateLeadBasicInformationProps {
  leadProject: CreateLeadProjectDTO;
  setLeadProject: React.Dispatch<Partial<CreateLeadProjectDTO>>;
}

function CreateLeadBasicInformation(
  props: CreateLeadBasicInformationProps
): ReactElement {
  const { leadProject, setLeadProject } = props;
  const { isExternalUser } = useRoles();

  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    VentureClient[]
  >([]);

  useEffect(() => {
    if (!isExternalUser)
      VentureClientHttpService.getVentureClients()
        .then((ventureClients) => {
          setVentureClientSelectOptions(ventureClients);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(`Could not fetch venture clients: ${message}`, {
            variant: "error",
          });
        });
  }, [isExternalUser]);

  return (
    <Fragment>
      <Box display="flex" gap={3}>
        <TextField
          label="Name"
          data-testid="lead-project-name"
          required
          sx={{ flex: 3 }}
          value={leadProject.name}
          onChange={(event) =>
            setLeadProject({
              name: event.target.value,
            })
          }
        />
        {!isExternalUser && (
          <SelectInput
            id="venture-client"
            label="Venture Client"
            selectValues={ventureClientSelectOptions}
            value={leadProject.ventureClientId}
            onChange={(event) =>
              setLeadProject({
                ventureClientId: parseInt(event.target.value),
              })
            }
            editMode
            required
            ignoreValidation
            sx={{ flex: 2 }}
          />
        )}
      </Box>
      <TextField
        label="Background Information"
        data-testid="lead-project-background-information"
        required
        value={leadProject.backgroundInformation}
        multiline
        rows={3}
        onChange={(event) =>
          setLeadProject({
            backgroundInformation: event.target.value,
          })
        }
      />
    </Fragment>
  );
}

export default CreateLeadBasicInformation;
