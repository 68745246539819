import {
  Fragment,
  ReactElement,
  Reducer,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Tab,
  TabProps,
  Tabs,
  Typography,
} from "@mui/material";
import theme from "../../../../theme";
import useRoles from "../../../../Hooks/useRoles";
import CreateLeadBasicInformation from "./CreateLeadBasicInformation";
import CreateLeadOrigin from "./CreateLeadOrigin";
import { Check } from "@mui/icons-material";
import CreateLeadInitiative from "./CreateLeadInitiative";
import {
  CreateLeadProjectDTO,
  LeadProjectOriginType,
} from "../../../../Types/LeadProject";
import { useSnackbar } from "notistack";
import { LeadProjectsHttpService } from "../../../../Http/LeadProjects/LeadProjects.http.service";
import { getErrorMessage } from "../../../../utils";
import { AxiosError } from "axios";

const StyledTabs = styled(Tabs)({
  display: "flex",
  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(0.5),
    borderBottom: "none",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.surface.action.main,
  },
});

const StyledTab = styled(Tab, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $status }: { $status?: StepStatusType }) => ({
  width: "33.33%",
  "&::after": {
    content: "''",
    position: "absolute",
    backgroundColor:
      $status === "COMPLETED"
        ? theme.palette.surface.action.secondary
        : theme.palette.surface.medium,
    height: 2,
    bottom: 0,
    width: "100%",
  },
}));

const StyledNumberContainer = styled(Avatar, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $status }: { $status?: StepStatusType }) => {
  const backgroundColor = (() => {
    switch ($status) {
      case "CURRENT":
        return theme.palette.surface.action.main;
      case "COMPLETED":
        return theme.palette.surface.action.secondary;
      default:
        return theme.palette.surface.medium;
    }
  })();

  const color = (() => {
    switch ($status) {
      case "CURRENT":
        return theme.palette.text.primaryInvert.main;
      case "COMPLETED":
        return theme.palette.icon.action.main;
      default:
        return theme.palette.text.disabled;
    }
  })();

  return {
    width: 16,
    height: 16,
    backgroundColor,
    color,
    ...theme.typography.overline,
  };
});

interface NewLeadProjectRequiredFields {
  Name?: string;
  "Venture Client"?: number;
  "Background Information"?: string;
  originType?: LeadProjectOriginType;
  Contact?: number;
  Startup?: number;
  Category?: string;
  "Category Name"?: number;
  "Initiative Name"?: number;
}

interface CreateLeadProjectModalProps {
  handleModalClose: (createdLeadId?: number) => void;
  modalOpen: boolean;
  defaultLeadProject?: Partial<CreateLeadProjectDTO>;
}

function CreateLeadProjectModal(
  props: CreateLeadProjectModalProps
): ReactElement {
  const { isExternalUser, ventureClientId } = useRoles();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(0);
  const [newLeadProject, setNewLeadProject] = useReducer<
    Reducer<CreateLeadProjectDTO, Partial<CreateLeadProjectDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    (props.defaultLeadProject ?? {}) as CreateLeadProjectDTO
  );

  useEffect(() => {
    if (isExternalUser && ventureClientId) {
      setNewLeadProject({ ventureClientId });
    }
  }, []);

  const newProjectRequiredFields: NewLeadProjectRequiredFields[] = [
    {
      Name: newLeadProject.name,
      ...(isExternalUser
        ? {}
        : { "Venture Client": newLeadProject.ventureClientId }),
      "Background Information": newLeadProject.backgroundInformation,
    },
    {
      originType: newLeadProject.originType,
      ...(newLeadProject.originType === "Organizational Unit"
        ? {
            Contact: newLeadProject.originContactId || undefined,
          }
        : {}),
      ...(newLeadProject.originType === "Startup"
        ? {
            Startup: newLeadProject.originStartupId || undefined,
          }
        : {}),
      ...(newLeadProject.originType === "Third Party"
        ? {
            Category: newLeadProject.originThirdPartyCategory,
            "Category Name":
              newLeadProject.originThirdPartyCategoryNameId || undefined,
          }
        : {}),
    },
    {
      ...(newLeadProject.initiativeType
        ? { "Initiative Name": newLeadProject.initiativeNameId || undefined }
        : {}),
    },
  ];

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(newProjectRequiredFields[step])) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const emptyRequiredFields = checkRequiredFields();

  const handleChangeTab = async (
    _?: React.SyntheticEvent,
    newValue?: number
  ) => {
    if (newValue === undefined) return;

    // if next step
    if (newValue > step && emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    if (nextStepState === "Next" || newValue < step) {
      setStep(newValue);
    } else {
      try {
        const createdLeadID = await LeadProjectsHttpService.createLeadProject({
          ...newLeadProject,
          ...(isExternalUser ? { ventureClientId } : {}),
        });
        props.handleModalClose(createdLeadID);
      } catch (error) {
        const message = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not create lead project: ${message}`, {
          variant: "error",
        });
      }
    }
  };

  const nextStepState = (() => {
    if (step === 1 && newLeadProject.originType === "Third Party") {
      return "Save";
    } else if (step === 2 && newLeadProject.initiativeType) {
      return "Save";
    } else if (step === 2 && !newLeadProject.initiativeType) {
      return "Skip & Save";
    }
    return "Next";
  })();

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid="create-new-lead-project-modal"
    >
      <DialogTitle>Create Lead</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <StyledTabs value={step} onChange={handleChangeTab}>
            <CustomTab index={1} name="Basic Information" currentValue={step} />
            <CustomTab index={2} name="Origin" currentValue={step} />
            <CustomTab index={3} name="Initiative" currentValue={step} />
          </StyledTabs>
          <CustomTabPanel index={1} value={step}>
            <CreateLeadBasicInformation
              leadProject={newLeadProject}
              setLeadProject={setNewLeadProject}
            />
          </CustomTabPanel>
          <CustomTabPanel index={2} value={step}>
            <CreateLeadOrigin
              leadProject={newLeadProject}
              setLeadProject={setNewLeadProject}
            />
          </CustomTabPanel>
          <CustomTabPanel index={3} value={step}>
            <CreateLeadInitiative
              leadProject={newLeadProject}
              setLeadProject={setNewLeadProject}
            />
          </CustomTabPanel>
        </Stack>
      </DialogContent>
      <DialogActions>
        {step > 0 && (
          <Button
            sx={{ marginRight: "auto" }}
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
        )}
        <Button onClick={() => props.handleModalClose()}>Cancel</Button>
        <Button
          onClick={() => handleChangeTab(undefined, step + 1)}
          variant="contained"
          data-testid="next-lead-button"
          disabled={emptyRequiredFields.length > 0}
        >
          {nextStepState}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface CustomTabProps extends TabProps {
  index: number;
  name: string;
  currentValue: number;
}

type StepStatusType = "CURRENT" | "COMPLETED" | "AWAITING";

const CustomTab = (props: CustomTabProps) => {
  const { index, name, currentValue, ...tabProps } = props;

  const status: StepStatusType = (() => {
    if (currentValue + 1 === index) return "CURRENT";
    if (currentValue + 1 > index) return "COMPLETED";
    return "AWAITING";
  })();

  return (
    <StyledTab
      {...tabProps}
      disabled={status === "AWAITING"}
      $status={status}
      label={
        <Box display="flex" alignItems="center" gap={1}>
          <StyledNumberContainer $status={status}>
            {status === "COMPLETED" ? <Check fontSize="inherit" /> : index}
          </StyledNumberContainer>
          <Typography>{name}</Typography>
        </Box>
      }
    />
  );
};

interface CustomTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: CustomTabPanelProps): ReactElement | null {
  const { children, value, index } = props;

  if (index !== value + 1) return null;
  return <Fragment>{children}</Fragment>;
}

export default CreateLeadProjectModal;
