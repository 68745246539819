import {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
} from "@mui/x-data-grid-premium";
import { ReactElement, useEffect } from "react";
import CustomDataGrid from "./CustomDataGrid";
import UseCase from "../../Types/UseCase";
import { LeadOpportunity, LeadProjectLeader } from "../../Types/LeadProject";
import useRoles from "../../Hooks/useRoles";
import { Project } from "../../Types/Project";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { formatDate, getNameWithDepartmentCode } from "../../utils";

type GridColumn = GridColDef & {
  hidden?: boolean;
};

interface LeadProjectsDataGridProps
  extends Omit<DataGridPremiumProps, "columns"> {
  apiRef: React.MutableRefObject<GridApiPremium>;
  gridState: GridInitialState | null;
}

export default function LeadProjectsDataGrid(
  props: LeadProjectsDataGridProps
): ReactElement {
  const { isExternalUser } = useRoles();
  const { apiRef, gridState, ...rest } = props;

  useEffect(() => {
    if (gridState) {
      apiRef.current.restoreState(gridState);
    }
  }, [apiRef, gridState]);

  const baseColumns: GridColumn[] = [
    {
      flex: 0.5,
      field: "id",
      headerName: "Lead ID",
      editable: false,
      valueFormatter: (value) => value,
    },
    {
      flex: 1,
      field: "name",
      headerName: "Lead Name",
      editable: false,
    },
    {
      flex: 1,
      field: "shortDescription",
      headerName: "Short Description",
    },
    ...(!isExternalUser
      ? [
          {
            flex: 1,
            field: "ventureClient",
            headerName: "Venture Client",
            editable: false,
            valueGetter: (_value, row) => row.ventureClient.name,
          } as GridColumn,
        ]
      : []),
    {
      flex: 0.5,
      field: "status",
      headerName: "Status",
    },
    {
      flex: 1,
      field: "statusComment",
      headerName: "Latest Status Update Comment",
    },
    {
      flex: 1,
      field: "originType",
      headerName: "Origin Type",
    },
    {
      flex: 1,
      field: "originContact",
      headerName: "Origin Contact",
      valueGetter: (_value, row) => row.originContact?.name,
    },
    {
      flex: 1,
      field: "originStartup",
      headerName: "Origin Startup",
      valueGetter: (_value, row) => row.originStartup?.name,
    },
    {
      flex: 1,
      field: "originThirdPartyCategory",
      headerName: "Origin Third Party Category",
      valueGetter: (_value, row) =>
        row.originThirdPartyCategoryName?.categoryType,
    },
    {
      flex: 1,
      field: "originThirdPartyName",
      headerName: "Origin Third Party Name",
      valueGetter: (_value, row) => row.originThirdPartyCategoryName?.value,
    },
    {
      flex: 1,
      field: "initiativeType",
      headerName: "Initiative Type",
      valueGetter: (_value, row) => row.initiativeName?.initiativeType,
    },
    {
      flex: 1,
      field: "initiativeName",
      headerName: "Initiative Name",
      valueGetter: (_value, row) => row.initiativeName?.value,
    },
    {
      flex: 0.5,
      field: "numberOfLaunchedProjects",
      headerName: "No. of Launched Projects",
      type: "number",
      valueGetter: (_value, row) => row.projects?.length,
    },
    {
      flex: 1,
      field: "launchedProjects",
      headerName: "Launched Projects",
      valueGetter: (_value, row) =>
        row.projects.map((project: Project) => project.name).join(", "),
    },
    {
      flex: 0.5,
      field: "numberOfUseCases",
      headerName: "No. of Use Cases",
      type: "number",
      valueGetter: (_value, row) => row.useCases?.length,
    },
    {
      flex: 1,
      field: "useCases",
      headerName: "Use Cases",
      valueGetter: (_value, row) =>
        row.useCases.map((uc: UseCase) => uc.name).join(", "),
    },
    {
      flex: 0.5,
      field: "numberOfProjectLeaders",
      headerName: "No. of Project Leaders",
      type: "number",
      valueGetter: (_value, row) => row.leaders?.length,
    },
    {
      flex: 1,
      field: "leaders",
      headerName: "Project Leaders",
      valueGetter: (_value, row) =>
        row.leaders
          .filter((pl: LeadProjectLeader) => pl.projectLeader?.name)
          .map((pl: LeadProjectLeader) =>
            getNameWithDepartmentCode(pl.projectLeader)
          )
          .join(", "),
    },
    {
      flex: 0.5,
      field: "numberOfStartups",
      headerName: "No. of Startups",
      type: "number",
      valueGetter: (_value, row) => row.opportunities?.length,
    },
    {
      flex: 1,
      field: "startups",
      headerName: "Startups",
      valueGetter: (_value, row) =>
        row.opportunities
          .map((opportunity: LeadOpportunity) => opportunity.startup?.name)
          .join(", "),
    },
    {
      flex: 1,
      type: "date",
      field: "dateCreated",
      headerName: "Creation Date",
      valueFormatter: (value) => formatDate(value),
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <CustomDataGrid
      {...rest}
      apiRef={apiRef}
      initialState={
        gridState || {
          columns: {
            columnVisibilityModel: {
              id: false,
              launchedProjects: false,
              useCases: false,
              leaders: false,
              startups: false,
              statusComment: false,
              shortDescription: false,
              dateCreated: false,
              originContact: false,
              originStartup: false,
              originThirdPartyCategory: false,
              originThirdPartyName: false,
              initiativeName: false,
            },
          },
        }
      }
      columns={columns}
      noRowsText="No Lead Found"
    />
  );
}
