import {
  Dialog,
  DialogTitle,
  Button,
  styled,
  Typography,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Stack,
  InputLabel,
  DialogActions,
} from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import EditMeetingModal from "./EditMeetingModal";
import theme from "../../../../../theme";
import { Meeting } from "../../../../../Types/Meeting";
import ClientContactSelect from "../../../../UI/InputFields/ClientContactSelect";
import StartupContactSelect from "../../../../UI/InputFields/StartupContactSelect";
import { ExpandMore } from "@mui/icons-material";
import parse from "html-react-parser";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../../../../utils";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";

const DateDisplayWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "208px",
  gap: theme.spacing(0.5),
}));

const DateDisplay = styled(Chip)(() => ({
  borderRadius: theme.shape.radius.minimal,
  color: theme.palette.text.brand.accessibility,
  border: `1px solid ${theme.palette.surface.brand.accessibility}`,
}));

const LocationWrapper = styled(Box)(() => ({
  display: "grid",
  alignItems: "start",
  gap: theme.spacing(0.5),
}));

const ContactWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

const DeliverablesWrapper = styled(Stack)(() => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.surface.secondary.main,
  gap: theme.spacing(1),
}));

const MeetingNotesAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  "&::before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
}));

const MeetingNotesAccordionDetails = styled(AccordionDetails)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: theme.spacing(0, 4),
}));

interface Props {
  index: number | null;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  meeting: Meeting;
  handleMeetingEdit: (meeting: Meeting, isPushMeetingsEnabled: boolean) => void;
  startupId: number;
  ventureClientId: number;
  maxDate: Date | null;
  minDate: Date | null;
}

const ViewMeetingModal = (props: Props): ReactElement => {
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false);
  const { activeStep } = useContext(GlobalProjectEditContext);

  const dateStart = props.meeting.dateStart
    ? formatDate(new Date(props.meeting.dateStart))
    : null;
  const title = props.meeting.title;
  const meetingType = props.meeting.type;
  const isMilestone = meetingType === "Milestone";
  const meetingDisplayType =
    meetingType === "Kick-off" ? "Kickoff" : meetingType;
  const isPilotStage = activeStep === 3;

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        fullWidth
        data-testid="view-meeting-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>
          {meetingDisplayType} {isMilestone ? props.index : ""}
          <IconButton
            sx={{ color: "icon.primary" }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <ScrollableDialogContent>
          <Stack gap={3}>
            <Typography variant="subtitle1" data-testid="title">
              {title ? title : "--"}
            </Typography>
            <Box display="flex" gap={2}>
              <DateDisplayWrapper>
                <Typography variant="caption" color="text.mediumEmphasis">
                  Date & Time
                </Typography>
                {dateStart ? (
                  <DateDisplay
                    variant="outlined"
                    label={dateStart}
                    size="medium"
                  />
                ) : (
                  <Typography variant="caption">--</Typography>
                )}
              </DateDisplayWrapper>
              <LocationWrapper data-testid="location-wrapper">
                <Typography variant="caption" color="text.mediumEmphasis">
                  Location
                </Typography>
                <Typography variant="caption" color="text.highEmphasis">
                  {props.meeting.location || "--"}
                </Typography>
              </LocationWrapper>
            </Box>
            <DeliverablesWrapper data-testid="deliverables">
              <InputLabel>
                <Typography variant="caption">Deliverables</Typography>
              </InputLabel>
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {props.meeting.deliverables ? props.meeting.deliverables : "--"}
              </Typography>
            </DeliverablesWrapper>
            <ContactWrapper>
              <ClientContactSelect
                labelText="Venture Client Stakeholders"
                ventureClientId={props.ventureClientId}
                onChange={() => null}
                fieldId="ventureClientStakeholders"
                multiSelect={true}
                editMode={false}
                contactData={props.meeting?.ventureClientStakeholders}
                orientation="horizontal"
                layoutColumn={3}
              />
              <StartupContactSelect
                editMode={false}
                labelText="Startup Stakeholders"
                onChange={() => null}
                startupId={props.startupId}
                contactData={props.meeting?.startupStakeholders}
                fieldId="startupStakeholders"
                multiSelect={true}
                orientation="horizontal"
                layoutColumn={3}
              />
            </ContactWrapper>
          </Stack>
        </ScrollableDialogContent>
        {isPilotStage && (
          <MeetingNotesAccordion data-testid="meeting-notes-accordion">
            <AccordionSummary
              sx={{ p: theme.spacing(0, 4) }}
              expandIcon={<ExpandMore />}
            >
              <Typography variant="subtitle2">Meeting Notes</Typography>
            </AccordionSummary>
            <MeetingNotesAccordionDetails>
              <Box>
                <Typography variant="caption" color="text.mediumEmphasis">
                  General Meeting Notes
                </Typography>
                <Typography>
                  {parse(props.meeting.generalMeetingNotes ?? "-")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" color="text.mediumEmphasis">
                  Next Steps
                </Typography>
                <Typography>{parse(props.meeting.nextSteps ?? "-")}</Typography>
              </Box>
            </MeetingNotesAccordionDetails>
          </MeetingNotesAccordion>
        )}
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsEditMeetingModalOpen(true)}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      {isEditMeetingModalOpen && (
        <EditMeetingModal
          modalOpen={isEditMeetingModalOpen}
          setModalOpen={(status) => {
            setIsEditMeetingModalOpen(status);
            props.setModalOpen(status);
          }}
          meeting={props.meeting}
          handleMeetingEdit={props.handleMeetingEdit}
          startupId={props.startupId}
          ventureClientId={props.ventureClientId}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      )}
    </>
  );
};

export default ViewMeetingModal;
