import { ReactElement } from "react";
import { TableCell } from "@mui/material";
import { Opportunity } from "../../../../../../Types/Opportunity";
import LimitedNameList from "../LimitedNameList/LimitedNameList";
import { formatToBillion, isBillion } from "../../../../../../utils";

export function DetailsViewRowLabels(): ReactElement {
  return (
    <>
      <TableCell>Founding Year</TableCell>
      <TableCell>Location</TableCell>
      <TableCell>Employees</TableCell>
      <TableCell>Reference Clients</TableCell>
      <TableCell>Funding Stage</TableCell>
      <TableCell>Funding Amount (in USD)</TableCell>
      <TableCell className="extra-high-cell highlighted-border">
        Investor Names
      </TableCell>
    </>
  );
}

const formatFunding = (totalFunding: string, isUndisclosed: boolean) => {
  if (isUndisclosed) return "Funding is undisclosed";
  if (!totalFunding) return "-";

  const isBillionUnit = isBillion(totalFunding);
  const fundingUnit = isBillionUnit ? "B" : "M";
  const fundingValue = isBillionUnit
    ? formatToBillion(+totalFunding)
    : +totalFunding;

  return `$${fundingValue}${fundingUnit}`;
};

interface DetailsViewStartupCellsProps {
  opportunity: Opportunity;
}

export function DetailsViewStartupCells({
  opportunity,
}: DetailsViewStartupCellsProps): ReactElement {
  const { startup } = opportunity;

  return (
    <>
      <TableCell>{startup.dateFounded?.getFullYear() || "-"}</TableCell>
      <TableCell>{startup.billingCountry || "-"}</TableCell>
      <TableCell>{startup.employees || "-"}</TableCell>
      <TableCell>
        <LimitedNameList entityNames={startup.referenceClients} />
      </TableCell>
      <TableCell>{startup.currentInvestmentStage || "-"}</TableCell>
      <TableCell>
        {formatFunding(startup.totalFunding, startup.fundingIsUndisclosed)}
      </TableCell>
      <TableCell className="extra-high-cell highlighted-border">
        <LimitedNameList entityNames={startup.investors} />
      </TableCell>
    </>
  );
}
