import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { ReactElement, useState, useEffect } from "react";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import { ClientContactDTO } from "../../../Types/ClientContact";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { Gender, SelectOption } from "../../../Types/Common";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { useCodependentSelectValues } from "../../ProjectKanban/useCodependentSelectValues";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import useRoles from "../../../Hooks/useRoles";

interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  contact: ClientContactDTO;
}

const genders = ["male", "female", "non-binary"];

export default function EditClientContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser } = useRoles();
  const [contact, setContact] = useState<ClientContactDTO>(props.contact);
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [ventureClient, setVentureClient, businessUnitSelectOptions] =
    useCodependentSelectValues(
      props.contact.businessUnit.ventureClient,
      BusinessUnitHttpService.getBusinessUnits
    );

  useEffect(() => {
    if (isExternalUser) return;
    VentureClientHttpService.getVentureClients().then((results) => {
      const ventureClientSelectOptions = results.map((ventureClient) => ({
        id: ventureClient.id,
        name: ventureClient.name,
      }));
      setVentureClientSelectOptions(ventureClientSelectOptions);
    });
  }, []);

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const handleUpdateContact = async () => {
    const requiredFields: { key: keyof ClientContactDTO; label: string }[] = [
      { key: "name", label: "Name" },
      { key: "title", label: "Title" },
      { key: "businessUnitId", label: "Organizational Unit" },
      { key: "email", label: "Email" },
    ];

    for (const { key, label } of requiredFields) {
      if (!contact[key] || `${contact[key]}`.trim() === "") {
        showError(label);
        return;
      }
    }

    try {
      await ClientContactHttpService.updateContact(contact, props.contact.id);
      props.handleSave();
      props.setModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Failed to update contact. Please try again.", {
        variant: "error",
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setContact({ ...contact, [id]: value });
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, gender: e.target.value as Gender });
  };

  const handleSelectBusinessUnit = (id: string) => {
    const buId = parseInt(id);
    if (buId) {
      setContact({
        ...contact,
        businessUnitId: buId,
      });
      return;
    }
  };

  const handleSelectVentureClient = (id: string) => {
    const vcId = parseInt(id);
    for (let index = 0; index < ventureClientSelectOptions.length; index++) {
      if (vcId === ventureClientSelectOptions[index].id) {
        setVentureClient(ventureClientSelectOptions[index]);
        setVentureClient(ventureClientSelectOptions[index]);
        return;
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="client-edit-view"
      data-testid="client-edit-view"
    >
      <DialogTitle>
        Edit Contact
        <IconButton
          sx={{ color: "icon.primary" }}
          onClick={() => props.setModalOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {contact && (
          <Stack gap={4}>
            <Stack gap={2}>
              <Typography variant="subtitle1">Personal Information</Typography>
              <Box display="flex" gap={2}>
                <TextField
                  label="Name"
                  id="name"
                  variant="outlined"
                  required
                  fullWidth
                  value={contact.name}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Gender"
                  id="gender"
                  variant="outlined"
                  fullWidth
                  select
                  value={contact.gender}
                  onChange={handleGenderChange}
                >
                  {genders.map((gender) => (
                    <MenuItem key={gender} value={gender}>
                      {gender}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Stack>

            <Stack gap={2}>
              <Typography variant="subtitle1">Company Information</Typography>
              <Box display="flex" gap={2}>
                <TextField
                  label="Title"
                  id="title"
                  variant="outlined"
                  fullWidth
                  required
                  value={contact.title}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Department Code"
                  id="departmentCode"
                  variant="outlined"
                  fullWidth
                  value={contact.departmentCode}
                  onChange={handleInputChange}
                />
              </Box>
              <Grid container spacing={2}>
                {!isExternalUser && (
                  <Grid item xs={6}>
                    <SelectInput
                      label="Venture Client"
                      selectValues={ventureClientSelectOptions}
                      value={
                        ventureClientSelectOptions.length
                          ? ventureClient.id
                          : ""
                      }
                      onChange={(e) =>
                        handleSelectVentureClient(e.target.value)
                      }
                      editMode={true}
                      required
                      fullWidth
                      ignoreValidation
                    />
                  </Grid>
                )}

                <Grid item xs={6}>
                  <SelectInput
                    label="Organizational Unit"
                    selectValues={businessUnitSelectOptions}
                    value={
                      businessUnitSelectOptions.length
                        ? contact.businessUnitId
                        : ""
                    }
                    onChange={(e) => handleSelectBusinessUnit(e.target.value)}
                    editMode={true}
                    disabled={
                      contact.businessUnit.ventureClient.id === undefined
                    }
                    required
                    fullWidth
                    ignoreValidation
                  />
                </Grid>
              </Grid>
            </Stack>

            <Stack gap={2}>
              <Typography variant="subtitle1">Contact</Typography>
              <Box display="flex" gap={2}>
                <TextField
                  label="Phone Number"
                  id="phone"
                  variant="outlined"
                  fullWidth
                  value={contact.phone}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Email"
                  id="email"
                  variant="outlined"
                  required
                  fullWidth
                  value={contact.email}
                  onChange={handleInputChange}
                />
              </Box>
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ mt: 0 }}>
        <Button
          onClick={() => props.setModalOpen(false)}
          id="close-client-edit-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdateContact}
          variant="contained"
          id="save-client-edit-view"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
