import {
  Dialog,
  DialogTitle,
  TextField,
  Stack,
  Button,
  DialogActions,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { ReactElement, Reducer, useReducer } from "react";
import { employeesOptions, legalEntities } from "../../../../Constants/Startup";
import { FileHttpService } from "../../../../Http/File/File.http.service";
import { StartupHttpService } from "../../../../Http/Startup/Startup.http.service";
import theme from "../../../../theme";
import { Startup, StartupOverview } from "../../../../Types/Startup";
import { getErrorMessage } from "../../../../utils";
import { AddressAutocomplete } from "../../../UI/InputFields/AddressAutocomplete";
import CustomDatePicker from "../../../UI/InputFields/CustomDatePicker";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import LogoUploadDropbox from "../../../UI/LogoUploadDropbox";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";

interface EditStartupOverviewModalProps {
  open: boolean;
  handleClose: () => void;
  startup: Startup;
  handleSave: () => void;
}

export default function EditStartupOverviewModal(
  props: EditStartupOverviewModalProps
): ReactElement {
  const addressInfo = [
    props.startup.billingStreet,
    props.startup.billingCity,
    props.startup.billingState,
    props.startup.billingCountry,
    props.startup.billingPostalCode,
  ];

  let startupAddress = "";
  addressInfo.forEach((info) => {
    if (info) {
      startupAddress = `${startupAddress && `${startupAddress}, `}${info}`;
    }
  });

  const [startup, setStartup] = useReducer<
    Reducer<StartupOverview, Partial<StartupOverview>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.startup.id,
    name: props.startup.name,
    legalEntity: props.startup.legalEntity,
    website: props.startup.website,
    dateFounded: props.startup.dateFounded,
    employees: props.startup.employees,
    billingStreet: props.startup.billingStreet,
    billingCity: props.startup.billingCity,
    billingState: props.startup.billingState,
    billingCountry: props.startup.billingCountry,
    billingPostalCode: props.startup.billingPostalCode,
    shortDescription: props.startup.shortDescription,
    description: props.startup.description,
  });

  const saveStartupOverview = async () => {
    await StartupHttpService.updateStartup(startup)
      .then(() => {
        props.handleSave();
        enqueueSnackbar("Startup saved successfully.", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update startup details: ${errorMessage}`, {
          variant: "error",
        });
      });

    props.handleClose();
  };

  const uploadLogo = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(file, startup.id, "startup", "Logo");
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle sx={{ pb: 0 }}>Edit Startup Details</DialogTitle>
      <ScrollableDialogContent>
        <Stack gap={3} pt={1}>
          <Stack direction="row" gap={2} sx={{ mr: "1px" }}>
            <Stack gap={3} width="100%">
              <TextField
                data-testid="name-field"
                label="Name"
                fullWidth
                required
                variant="outlined"
                inputProps={{
                  "data-testid": "name-input",
                }}
                value={startup.name}
                onChange={(e) => setStartup({ name: e.target.value })}
              />
              <Stack direction="row" gap={2}>
                <SelectInput
                  label="Legal Entity"
                  data-testid="legal-entity"
                  editMode={true}
                  selectValues={legalEntities.map((entity) => {
                    return { id: entity, name: entity };
                  })}
                  value={startup.legalEntity}
                  onChange={(event) =>
                    setStartup({ legalEntity: event.target.value })
                  }
                  fullWidth
                />
                <TextField
                  data-testid="website"
                  label="Website"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setStartup({ website: e.target.value })}
                  value={startup.website}
                />
              </Stack>
            </Stack>
            <LogoUploadDropbox addLogo={(file) => uploadLogo(file)} />
          </Stack>
          <Stack direction="row" gap={2}>
            <CustomDatePicker
              id="dateFounded"
              label="Founded"
              onChange={(value) => {
                if (value)
                  setStartup({
                    dateFounded: new Date(value),
                  });
              }}
              disableFuture
              editMode
              value={startup.dateFounded}
              views={["year"]}
              inputFormat="YYYY"
              fullWidth
            />
            <SelectInput
              label="Employees"
              data-testid="employees"
              editMode={true}
              selectValues={employeesOptions}
              value={startup.employees}
              onChange={(e) => setStartup({ employees: e.target.value })}
              disableSorting
              fullWidth
            />
          </Stack>

          <AddressAutocomplete
            defaultAddress={startupAddress}
            setStartup={setStartup}
          />

          <TextField
            data-testid="short-description"
            label="Short Description"
            fullWidth
            variant="outlined"
            onChange={(e) => setStartup({ shortDescription: e.target.value })}
            value={startup.shortDescription}
          />
          <TextField
            data-testid="full-description"
            label="Full Description"
            fullWidth
            multiline
            variant="outlined"
            onChange={(e) => setStartup({ description: e.target.value })}
            value={startup.description}
          />
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={props.handleClose}
          data-testid="cancel-button"
          id="cancel-overview-edit"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={saveStartupOverview}
          data-testid="save-button"
          id="save-overview-edit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
