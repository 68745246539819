import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import { SelectOption } from "../Types/Common";
import { StartupMainTabs } from "../Types/Startup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import theme from "../theme";
import { HIGHLIGHTED_SUB_TAB } from "./TooltipText";

export const employeesOptions: SelectOption[] = [
  { id: "1-10", name: "1-10" },
  { id: "11-25", name: "11-25" },
  { id: "26-50", name: "26-50" },
  { id: "51-100", name: "51-100" },
  { id: "101-250", name: "101-250" },
  { id: "251-500", name: "251-500" },
  { id: "500+", name: "500+" },
  { id: "Undisclosed", name: "Undisclosed" },
];

export const investmentStagesOptions: SelectOption[] = [
  { id: "Undisclosed", name: "Undisclosed" },
  { id: "No Institutional Investment", name: "No Institutional Investment" },
  { id: "Grant", name: "Grant" },
  { id: "Pre-Seed", name: "Pre-Seed" },
  {
    id: "Seed - Accelerator (no equity)",
    name: "Seed - Accelerator (no equity)",
  },
  { id: "Seed - Accelerator (equity)", name: "Seed - Accelerator (equity)" },
  { id: "Seed - Angel", name: "Seed - Angel" },
  { id: "Seed - VC", name: "Seed - VC" },
  { id: "Series A", name: "Series A" },
  { id: "Series B", name: "Series B" },
  { id: "Series C", name: "Series C" },
  { id: "Series D", name: "Series D" },
  { id: "Series E", name: "Series E" },
  { id: "Series F", name: "Series F" },
  { id: "Series G", name: "Series G" },
  { id: "Public", name: "Public" },
  { id: "Acquired", name: "Acquired" },
  { id: "Deadpooled", name: "Deadpooled" },
];

export const legalEntities = [
  "AB",
  "A.E.",
  "AG",
  "ApS",
  "AS",
  "BV",
  "Co. Ltd.",
  "Corp.",
  "D.O.O.",
  "FZCO",
  "GmbH",
  "Inc.",
  "Kft",
  "Ltd.",
  "LLP",
  "LLC",
  "N.V.",
  "OUE",
  "Other",
  "Oy",
  "PLC",
  "Pte. Ltd.",
  "Pty. Ltd.",
  "Pvt. Ltd.",
  "S.A.",
  "S.A. de C.V.",
  "SAPI de C.V.",
  "SAS",
  "SE",
  "SL",
  "Sp. z o.o.",
  "S.r.l.",
  "s.r.o.",
  "UG",
  "Unltd.",
  "PC",
  "S.p.A.",
];

export const technologyReadinessOptions: SelectOption[] = [
  { id: "Idea Stage", name: "Idea Stage" },
  { id: "Prototype Stage", name: "Prototype Stage" },
  { id: "Early Market Stage", name: "Early Market Stage" },
  { id: "Growth Market Stage", name: "Growth Market Stage" },
];

export const startupTabs: StartupMainTabs = [
  {
    title: "Database",
    description: "The full startup database",
    allowedVentureClientsIds: [],
    subTabs: [
      { title: "All" },
      { title: "Leads" },
      { title: "Curated" },
      { title: "Qualified" },
      { title: "Selected for Pilot" },
      { title: "Adopted" },
    ],
  },
  {
    title: "Startup Portfolio",
    description: "Startup assigned to any of your leads & projects",
    allowedVentureClientsIds: "all",
    subTabs: [
      { title: "All" },
      { title: "Leads" },
      { title: "Curated" },
      { title: "Qualified" },
      { title: "Selected for Pilot" },
      { title: "Adopted" },
    ],
  },
  {
    title: "SLUSH 2024",
    description: "Startups attending the SLUSH 2024 conference",
    allowedVentureClientsIds: "all",
    subTabs: [
      { title: "All" },
      {
        title: "Highlighted",
        tooltipText: HIGHLIGHTED_SUB_TAB,
      },
    ],
    featuredListId: 1,
  },
];

export const technologyReadinessOptionsDescriptions: Record<string, string> = {
  "Idea Stage":
    "The startup does not yet have a testable product or prototype available.",
  "Prototype Stage":
    "The startup has developed a working prototype of its product, which is now being tested and refined to move closer to a market-ready product.",
  "Early Market Stage":
    "The startup has an early version of its product available, which is currently being tested or commercialized with early adopters. The startup may still be in the process of identifying its product-market-fit.",
  "Growth Market Stage":
    "The startup is in the process of commercialising a mature and proven solution at scale and exhibits a growing client base.",
};

export const TechnologyReadinessTooltip: React.ReactElement = (
  <Stack p={theme.spacing(2, 3)} gap={2} width="600px">
    <Box display="flex" alignItems="center" gap={1}>
      <HelpOutlineIcon />
      <Typography variant="subtitle1">Technology Readiness</Typography>
    </Box>
    {Object.entries(technologyReadinessOptionsDescriptions).map(
      ([stage, description]) => (
        <Stack key={stage}>
          <Typography variant="subtitle2">{stage}</Typography>
          <Typography color="text.mediumEmphasis">{description}</Typography>
        </Stack>
      )
    )}
  </Stack>
);
